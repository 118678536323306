import React, { ReactElement } from "react";
import "../../styles/global/input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export type InputProps = {
  placeHolder?: string;
  updateFunc: any;
  image?: React.ReactNode;
  disabled?: boolean;
  password?: boolean;
  className?: string;
  value?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  id?: string;
  autoComplete?: string;
  type?: string;
  pattern?: string;
  min?: string;
  max?: string;
  tooltip?: string;
  label?: string;
  leftComponent?: ReactElement<any, any>;
};

const Input: React.FC<InputProps> = ({
  placeHolder,
  updateFunc,
  image,
  disabled,
  password,
  className,
  value,
  minLength,
  maxLength,
  required,
  id,
  autoComplete,
  type,
  pattern,
  min,
  max,
  tooltip,
  label,
  leftComponent
}) => {
  const [fieldType, setFieldType] = React.useState(false);

  const handleShowPassword = () => {
    setFieldType(!fieldType);
  }
  return (
    <div className="input-outer-container">
      {label ? <div className={`input-label-container ${label === "Search Address" ? "search-field-label" : ""}`}>{label}</div> : null}
      <div className={`input-container ${className || ""}`}>
        {leftComponent ? leftComponent : null}
        <input
          id={id}
          autoComplete={autoComplete}
          className="input-text-area"
          disabled={disabled}
          type={fieldType ? "text" : (type ? type : "text")}
          placeholder={placeHolder}
          onChange={updateFunc}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          required={required}
          pattern={pattern}
          min={min}
          max={max}
        />
        {type === "password" ? <span onClick={() => handleShowPassword()} >
          {fieldType ? <FontAwesomeIcon icon={faEye} className="field-icon" /> : <FontAwesomeIcon icon={faEyeSlash} className="field-icon" />} </span>
          : null}
        {image}
        {tooltip ? (
          <div className="input-tooltip">
            <div className="input-tooltip-icon">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="input-tooltip-text">{tooltip}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
