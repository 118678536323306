import React from "react";
import "../../styles/global/radioButton.scss";
import Lozenge from "./Lozenge";

export type RadioButtonColors = "brand";
export type RadioButtonWidths = "small" | "large";
export type RadioButtonBorder = "gray";
export type RadioButtonProps = {
  id: string;
  selectedClassName?: "" | "selected" | "notSelected";
  title?: string;
  price?: string;
  timeLength?: string;
  rightText?: string;
  middleText?: string;
  size: RadioButtonWidths;
  color: RadioButtonColors;
  border: RadioButtonBorder | String;
  discount?: boolean;
  onClick?: any;
  lozenge?: {
    showLozenge: boolean;
    text: string;
    textColor: string;
    boxColor: string;
  };
};

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  selectedClassName,
  title,
  price,
  timeLength,
  rightText,
  middleText,
  color,
  border,
  onClick,
  size,
  discount,
  lozenge
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`radio-btn-container ${size} ${color} ${border} ${selectedClassName}`}>
      {size === "large" ? (
        <div className="large-radio-btn-text-container">
          <div className="large-top-text-container">
            <span className="radio-btn-title">{title}</span>
            {lozenge.showLozenge && (
              <Lozenge
                text={lozenge.text}
                textColor={lozenge.textColor}
                boxColor={lozenge.boxColor}
              />
            )}
          </div>
          <div className="large-bottom-text-container">
            <div className="radio-btn-left-lower-text-container">
              <span className={`radio-btn-price ${border}`}>{price}</span>
              <span className="radio-btn-time-length">{timeLength}</span>
            </div>
            <div className={`radio-btn-right-text ${discount ? "discount-bg" : ""}`}>
              <span className={`${discount ? " discount-text wrap-text" : ""}`} title={rightText}>
                {rightText}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <span className="radio-btn-middle-text">{middleText}</span>
      )}
    </button>
  );
};

export default RadioButton;
