import React, { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FRONTEND_BASE_URL, RTV_BASE_URL } from "../../shared/constants";
import "../../styles/global/container.scss";
import Close from "./Close";
import { postMessageToParent } from "../../shared/Client";

export type ContainerProps = {
  component: ReactElement<any, any>;
  popup: boolean;
};

function isElementVisible(id: string) {
  let element = document.getElementById(id);

  if (element) {
    let rect = element.getBoundingClientRect();
    let windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // checks whether the element is within the viewport
    return rect.top < windowHeight;
  } else {
    console.warn(`Element with id ${id} doesn't exist.`);
    return false;
  }
}

const Container: React.FC<ContainerProps> = ({ component, popup = true }) => {
  const location = useLocation();
  const [scrolledToBottom, setScrolledToBottom] = React.useState<boolean | null>(null);

  const handleClose = () => postMessageToParent("close");

  // run handleScroll when location changes
  const handleScroll = () => {
    const nowScrolledToBottom = isElementVisible("bottom"); // @ts-ignore

    // set it, and send a message if its changed from what it was in state
    setScrolledToBottom(nowScrolledToBottom); //nowScrolledToBottom);

    if (nowScrolledToBottom !== scrolledToBottom) {
      postMessageToParent(`scrolled_to_bottom:${nowScrolledToBottom}`);
    }
  };
  useEffect(() => {
    handleScroll();
    // and also run it whenever location changes
    window.addEventListener("scroll", handleScroll);
  }, [location.pathname, scrolledToBottom]);

  window.onload = () => {
    handleScroll();
  };
  // Scroll to the top when the url changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    window.parent.postMessage("live_chat", FRONTEND_BASE_URL);
  }, []);

  return (
    <div id="main-container">
      {popup ? <Close onClick={() => handleClose()} journey={component.props.journey} /> : null}
      {component}
      <div id="bottom" style={{ height: 10 }}></div>
    </div>
  );
};

export default Container;
