import React from "react";
import { useEffect } from "react";

const LoadSuccessPageAnalytics = () => {

  useEffect(() => {
    const cart = JSON.parse(`${sessionStorage.getItem('cart')}`)

    if (cart) {
      window.parent.postMessage({ source: 'twitter_events', amount: `${cart?.total}` }, '*')
    }

    sessionStorage.removeItem('cart')

  }, [])

  let google_conversion_language = "en";
  let google_conversion_id = 1052924346;
  let google_conversion_format = "2";
  let google_conversion_color = "ffffff";
  let google_conversion_label = "_AqNCKTeqAgQurOJ9gM";
  let google_remarketing_only = false;

  const tWidgets = document.createElement('script');
  tWidgets.src = 'https://platform.twitter.com/widgets.js';
  tWidgets.charset = "utf-8";
  tWidgets.async = true;
  tWidgets.defer = true;
  document.body.appendChild(tWidgets);

  const tWidgetsMeta = document.createElement('meta');
  tWidgetsMeta.name = "twitter:widgets:link-color";
  tWidgetsMeta.content = "#009fe2"
  document.body.appendChild(tWidgetsMeta);

  const gAdScript = document.createElement('script');
  gAdScript.src = '//www.googleadservices.com/pagead/conversion.js'
  document.body.appendChild(gAdScript);

  const tOctScript = document.createElement('script');
  tOctScript.src = '//platform.twitter.com/oct.js'
  document.body.appendChild(tOctScript);
}
export default LoadSuccessPageAnalytics;