import React from "react";
import "../../styles/global/checkBox.scss";

export type CheckBoxProps = {
  text: any;
  id: string;
  required?: boolean;
  noMarginBottom?: boolean;
  checked?: boolean;
  handler?: any;
};

const CheckBox: React.FC<CheckBoxProps> = ({ text, id, required = false, noMarginBottom, checked, handler }) => {
  return (
    <div
      className={`checkbox-container ${text.length < 50 ? "short" : ""} ${
        noMarginBottom ? "no-margin-bottom" : ""
      }`}>
      <input
        className="checkbox"
        type="checkbox"
        id={id}
        name={text}
        value={id}
        required={required}
        checked={checked}
        onClick={(e) => handler ? handler(e) : null}
      />
      <label className={`checkbox-label`} htmlFor={text}>
        {text}
      </label>
    </div>
  );
};

export default CheckBox;
