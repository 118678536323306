import React from "react";
import "./App.scss";
import Container from "./components/global/Container";
import { Route, Switch } from "react-router-dom";
import Journey from "./shared/Journey";
import Login from "./shared/Login";

function App() {
  // get params
  const params = new URLSearchParams(window.location.search);
  const popup = params.get("popup") ? params.get("popup")?.toLowerCase() === "true" : true;
  return (
    <>
      <Switch>
        <Route path="/login">
          <Container popup={popup} component={<Login />} />
        </Route>
        <Route path="/fdp">
          <Container popup={popup} component={<Journey journey={"free_day_pass"} />} />
        </Route>
        <Route path="/join">
          <Container popup={popup} component={<Journey journey={"join"} />} />
        </Route>
      </Switch>
    </>
  );
}

export default App;
