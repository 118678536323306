export const parseSearch = (search, extractKey) => {
  // Remove the initial '?'
  const query = search.substring(1);
  const pairs = query.split("&");
  let encodedJson = "";

  for (let pair of pairs) {
    const [key, value] = pair.split("=");
    if (key === extractKey) {
      encodedJson = value;
      break;
    }
  }

  if (encodedJson) {
    try {
      return JSON.parse(decodeURIComponent(encodedJson));
    } catch (e) {
      console.error(`Error parsing ${extractKey}:`, e);
      return {};
    }
  }

  return {};
};
