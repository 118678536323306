import React from "react";
import "../../App.scss";
import { ReactComponent as Cross } from "../../svg/cross.svg";
import { ReactComponent as Logo } from "../../svg/logo.svg";
import "../../styles/global/close.scss";
import { JourneyType } from "../../shared/Theme";

export type CloseProps = {
  onClick: Function;
  journey?: JourneyType;
};

const Close: React.FC<CloseProps> = ({ onClick, journey = "" }) => {
  const [hideCross] = React.useState(false);

  return (
    <>
      {window.matchMedia("only screen and (max-width: 760px)").matches ? (
        <div
          className={`${
            journey === "free_day_pass"
              ? "free-day-pass-journey-close-container"
              : "close-container"
          }`}>
          <span className="thirty"></span>
          {journey === "free_day_pass" ? null : <Logo />}
          {hideCross ? (
            <span className="row-spacer thirty"></span>
          ) : (
            <Cross className="cross" onClick={() => onClick()} />
          )}
        </div>
      ) : null}
    </>
  );
};

export default Close;
