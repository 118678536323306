import {
  RTV_API_KEY,
  RTV_BASE_URL,
  MEMBER_TOKEN_NAME,
  BASIC_AUTH_TOKEN,
  ADDRESS_FINDER_API_KEY,
  ADDRESS_FINDER_BASE_URL,
  FRONTEND_BASE_URL,
  ENV
} from "./constants";
import {
  ApiResponsePaymentStatus,
  ApiResponsePlan,
  CountryCode,
  DiscountPlan,
  Plan
} from "../shared/Models";
import { Customer } from "../join/YourDetails";
import { DirectDebitDetails } from "../join/Payment";

const handleError = (status: number, res: any) => {
  let errName;
  if (status >= 200 && status < 300) return;
  if (status === 500) {
    errName = "Internal Server Error";
  }
  try {
    errName = res.error.message.toString() || "weird response type.";
  } catch (err) {
    errName = "Sorry. Something went wrong.";
  }
  if (errName) {
    throw new Error(errName);
  }
};

const formatQueryParams = (queryParams: { [key: string]: any } = {}) => {
  const items: any = queryParams;
  return Object.keys(items)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(items[k]))
    .join("&");
};

const formatQueryParamsWithoutEncoding = (queryParams: { [key: string]: any } = {}) => {
  const items: any = queryParams;
  return Object.keys(items)
    .map((k) => encodeURIComponent(k) + "=" + items[k].replace(/\s/g, "%20"))
    .join("&");
};

const getHeaders = (token: string | undefined): any => {
  if (!token?.length) token = sessionStorage.getItem("memberToken");
  const headers: any = {
    "Content-Type": "application/json",
    // "X-Requested-With": `racingtv-web/1.0`,
    "API-KEY": RTV_API_KEY,
    Authorization: token ? `Bearer ${token}` : ""
  };

  return headers;
};

const fetchPost = async (
  endpoint: string,
  queryParams: { [key: string]: any } = {},
  body: any,
  method = "POST",
  token?: string,
  v2 = true
): Promise<any> => {
  const headers = getHeaders(token);
  const reqUrl = `${RTV_BASE_URL}${endpoint}?${formatQueryParams(queryParams)}`;
  return fetch(reqUrl, { method: method, headers, body: JSON.stringify(body) }).then(async (r) => {
    const res = await r.json();
    handleError(r.status, res);
    return res;
  });
};

const fetchGet = async (
  baseUrl = RTV_BASE_URL,
  endpoint: string,
  queryParams: { [key: string]: any } = {},
  token?: any,
  encode?: boolean
): Promise<any> => {
  const headers = getHeaders(token);
  let reqUrl;
  if (encode) {
    reqUrl = `${baseUrl}${endpoint}?${formatQueryParams(queryParams)}`;
  } else {
    reqUrl = `${baseUrl}${endpoint}?${formatQueryParamsWithoutEncoding(queryParams)}`;
  }
  return fetch(reqUrl, { headers }).then(async (r) => {
    const res = await r.json();
    handleError(r.status, res);
    return res;
  });
};

export const login = async (
  email: string,
  password: string,
  captchaValue: string | null
): Promise<any> => {
  return fetchPost(
    "member/authentication/login",
    {},
    {
      username: email,
      password: password
    },
    "POST",
    undefined,
    false
  );
};

export const signup = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  consent_marketing: string,
  consent_third_party: string,
  consent_racecourse_affiliates: string,
  free_account: string
): Promise<any> => {
  return fetchPost(
    "member/authentication/sign_up",
    {},
    {
      customer: {
        name_prefix: "Mx", // Neutral catch all
        name_given: firstName,
        name_family: lastName,
        username: email, // yes really
        consent_age: "1",
        consent_ruk: consent_marketing, // not implemented at the moment
        consent_bookmakers: consent_third_party,
        consent_racecourse_affiliates: consent_racecourse_affiliates,
        password: password,
        free_account
      }
    }
  );
};

export const validateCode = async (member: string, token: string, customer: any): Promise<any> => {
  return fetchPost(`member/payment/no_payment`, {}, customer, "POST", token);
};

export const lookupRestOfWorldAddress = async (
  pattern: string,
  countryCode: CountryCode
): Promise<any> => {
  return fetchGet(
    ADDRESS_FINDER_BASE_URL,
    `${ADDRESS_FINDER_API_KEY}/address/${countryCode}/${encodeURIComponent(pattern)}`,
    { format: "json", lines: "3" },
    undefined,
    true
  );
};

export const lookupAddress = async (
  pattern: string,
  countryCode: CountryCode,
  pathFilter: string,
  encode: boolean
): Promise<any> => {
  return fetchGet(
    `${ADDRESS_FINDER_BASE_URL}`,
    `autocomplete/find`,
    {
      query: pattern,
      pathfilter: pathFilter,
      country: countryCode,
      ApiKey: ADDRESS_FINDER_API_KEY
    },
    encode
  );
};

export const getAddressDetail = async (
  addressId: string,
  countryCode: CountryCode
): Promise<any> => {
  return fetchGet(`${ADDRESS_FINDER_BASE_URL}`, `autocomplete/retrieve/`, {
    id: addressId,
    country: countryCode,
    ApiKey: ADDRESS_FINDER_API_KEY,
    lines: "3"
  });
};

export const getLiveProgrammes = async (): Promise<any> => {
  return fetchGet(`${RTV_BASE_URL}api/`, `programmes/live`, { only_live_video: "true" });
};

export const alreadyLoggedIn = async (token: string): Promise<any> => {
  return fetchGet(`${RTV_BASE_URL}`, `members/already_logged_in`, {}, token);
};

export const getAvailablePlans = async (token: string): Promise<ApiResponsePlan> => {
  return fetchGet(`${RTV_BASE_URL}/`, `member/plans`, {}, token);
};

export const updatePlan = async (data: any, token: string): Promise<any> => {
  return fetchPost("member/carts", {}, data, "POST", token);
};

export const getPaymentStatus = async (token?: string): Promise<ApiResponsePaymentStatus> => {
  return fetchGet(`${RTV_BASE_URL}/`, `member/payment/status`, { version: `${Date.now()}` }); // haven't included token here. shouldn't need it.
};

export const updateDetails = async (token: string, customer: any) => {
  // todo, pass in addr line 1, 2, 3 etc
  return fetchPost(`member/update`, {}, customer, "PUT", token);
};

export const addDiscountCode = async (code: string, passFreeDayCode: boolean): Promise<any> => {
  return passFreeDayCode
    ? fetchPost(
        "member/carts/discount",
        {},
        { cart: { discount_code: code } },
        "PUT",
        sessionStorage.getItem("memberToken") || ""
      )
    : fetchPost(
        "member/carts/discount",
        {},
        { cart: { discount_code: code } },
        "PUT",
        sessionStorage.getItem("memberToken") || ""
      );
};

export const addSkyVcn = async (vnc: string, sky_q: string): Promise<any> => {
  return fetchPost(
    "member/carts/sky_vcn",
    {},
    { cart: { vcn_number: vnc, sky_q: sky_q } },
    "POST",
    sessionStorage.getItem("memberToken") || ""
  );
};

export const getCart = async () => {
  // /api/v2/purchase
  return fetchGet(
    RTV_BASE_URL,
    `member/carts/current`,
    {},
    sessionStorage.getItem("memberToken") || ""
  ); // we should need to pass this in if we get memberToken from session storage in getHeaders
};

// export const fetchCard = async () => {
//   return fetchGet(
//     RTV_BASE_URL,
//     "/member/payments/cards/new",
//     {},
//     sessionStorage.getItem("memberToken") || ""
//   );
// };

export const sendDirectDebit = async (data: DirectDebitDetails) => {
  // {
  //   "sort_code": "830608",
  //   "account_number": "10498522",
  //   "account_name": "Test Account"
  // }

  return fetchPost(
    "member/payment/direct_debit",
    {},
    data,
    "POST",
    sessionStorage.getItem("memberToken") || ""
  );
};

declare const window: any;

export const postMessageToParent = (message: any, override?: null) => {
  if (window.ReactNativeWebView) {
    // send data object to React Native (only string)
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  } else {
    window.parent.postMessage(message, "*");
  }
};
