import React, { useEffect, useState } from "react";
import Signup from "./Signup";
import Success from "../free_day_pass/Success";
import Verify from "../free_day_pass/Verify";
import { Switch, Route } from "react-router-dom";

import "../styles/global/journey.scss";
import { JourneyType } from "./Theme";
import ChoosePackage from "../join/ChoosePackage";
import YourDetails from "../join/YourDetails";
import Payment from "../join/Payment";
import { RTV_BASE_URL } from "./constants";
import { alreadyLoggedIn } from "../shared/Client";
import { useHistory } from "react-router-dom";
import Alert from "../components/global/Alert";
import Loader from "../components/global/Loader";

export type JourneyProps = {
  journey: JourneyType;
};

const sessionStorageUnavailable = () => {
  try {
    sessionStorage.getItem("test");
  } catch (e) {
    return e.message.length > 0;
  }
};

const Journey: React.FC<JourneyProps> = ({ journey }) => {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (sessionStorageUnavailable()) {
      setError("StorageUnavailable");
      return;
    }

    if (
      sessionStorage.getItem("memberToken") == null &&
      localStorage.getItem("memberToken") != null
    ) {
      for (let i = 0; i < localStorage.length; i++) {
        let key_data = localStorage.key(i) || "";
        sessionStorage.setItem(key_data, localStorage.getItem(key_data) || "");
      }
    }

    const token = sessionStorage.getItem("memberToken");

    if (!token) {
      setLoggedIn(false);
      setLoading(false);
    } else {
      alreadyLoggedIn(token)
        .then((res: any) => {
          setLoggedIn(true);
          setLoading(false);
          if (res.subscription_stage == "payment_stage") {
            sessionStorage.setItem("payment_error", res.error);
            return history.push(`/join/payment`);
          } else {
            sessionStorage.setItem("payment_error", "");
          }
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error && error == "StorageUnavailable") {
    return (
      <Alert>
        We're unable to access your browser storage - which means you wont be able to login! Please
        open <div style={{ fontWeight: "bold" }}>chrome://settings/cookies</div> and ensure that
        "Allow all cookies" is selected.
      </Alert>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/fdp/verify">
          <Verify journey={journey} />
        </Route>
        <Route path="/fdp/success">
          <Success journey={journey} />
        </Route>
        <Route path="/join/choose-package">
          <ChoosePackage journey={journey} />
        </Route>
        <Route path="/join/your-details">
          <YourDetails journey={journey} />
        </Route>
        <Route path="/join/payment">
          <Payment journey={journey} />
        </Route>
        <Route path="/join/success">
          <Success journey={journey} />
        </Route>
        {!loading && (
          <Route path="/">
            {/*  Only show the signup page if they're not logged in */}
            {!loggedIn ? <Signup journey={journey} /> : <ChoosePackage journey={journey} />}
          </Route>
        )}
      </Switch>
    </>
  );
};

export default Journey;
