import React from "react";
import "../../styles/global/button.scss";

export type ButtonColors = "transparent" | "black";
export type ButtonWidths = "small" | "medium" | "wide" | "x-small";
export type ButtonProps = {
  text: string;
  size: ButtonWidths;
  color: ButtonColors | String;
  disabled?: boolean;
  onClick?: any;
  type?: any;
  className?: String;
  rightComponent?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  text,
  color,
  disabled,
  onClick,
  size,
  type,
  className,
  rightComponent
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`btn-container ${size} ${color} ${className}`}>
      <span className={`btn-text ${color || ""}`}>{text}</span>

      {rightComponent ? <span className="right-component">{rightComponent}</span> : null}
    </button>
  );
};

export default Button;
