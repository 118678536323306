import "../../styles/global/loader.scss";

const Loader = ({ color = "accent" }) => (
  <div className={`loader inline ${color}-contrast`}>
    <svg className="circular" viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  </div>
);

export default Loader;
