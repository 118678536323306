export const MEMBER_TOKEN_NAME = "MEMBER-TOKEN";
export const ALT_MEMBER_TOKEN_NAME = "_racing-uk_member_token";
export const MEMBER_TOKEN_MAX_AGE = 28 * 24 * 60 * 60; // 28 days
export const FREE_DAY_PASS_COOKIE = "free_day_pass_set";
export const FREE_DAY_PASS_MAX_AGE = 28 * 24 * 60 * 60; // 28 days

const env = process.env.REACT_APP_ENV || "development";

// Must have trailing slash.
const baseUrls: any = {
  development: "https://api.new-staging.racingtv.com/",
  staging: "https://api.new-staging.racingtv.com/",
  prod: "https://api.racingtv.com/"
};

const frontendURLS: any = {
  development: "http://localhost:19006",
  staging: "https://main.racing-tv.pages.dev",
  prod: "https://www.racingtv.com"
};

const apiKeys: any = {
  development: "d2d01b9a-a7b3-4dfc-8534-8b15615a54cd",
  staging: "d2d01b9a-a7b3-4dfc-8534-8b15615a54cd", // <- 359b6f85-....... is staging key
  prod: "82efe8af-c962-414a-8110-96ddd277d346" // <- using ios app api key here. Should make a new one
};

const addressFinderApiKeys: any = {
  development: "PCWGX-YRG8X-TNQVK-459TP",
  staging: "PCWGX-YRG8X-TNQVK-459TP", // <- TODO
  prod: "PCWGX-YRG8X-TNQVK-459TP" // <-  TODO
};

// only staging has basic auth token
const basicAuthTokens: any = {
  development: null,
  staging: "ald6WnNiTkZiMlA4Nng2bzpvUFJzaTdHNDRGaHFXTHQ0",
  prod: null
};

const captchaKeys: any = {
  development: "6LdU27gUAAAAAK39c-MaAXfB8mLtU_2EgAeds0cL",
  staging: "6LdU27gUAAAAAK39c-MaAXfB8mLtU_2EgAeds0cL",
  prod: "6LcoS7kUAAAAABnssgdAUY38ZWpybxeUCkFU4y_t"
};

export const ENV = env;
export const FRONTEND_BASE_URL = frontendURLS[env];
export const RTV_BASE_URL = baseUrls[env];
export const RTV_API_KEY = apiKeys[env];
export const BASIC_AUTH_TOKEN = basicAuthTokens[env];
export const ADDRESS_FINDER_BASE_URL = "https://ws.postcoder.com/pcw/";
export const ADDRESS_FINDER_API_KEY = addressFinderApiKeys[env];
export const RECAPTCHA_SITE_KEY = captchaKeys[env];
