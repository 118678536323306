import React from "react";
import "../../styles/global/successCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { JourneyType, JOURNEY_COLOURS, JOURNEY_SUCCESS_MESSAGES } from "../../shared/Theme";

export type SuccessCardProps = {
  journey: JourneyType;
};

const SuccessCard: React.FC<SuccessCardProps> = ({ journey }) => {
  return (
    <div className="success-message-container">
      <div className="success-message-text">
        <h1 className={`success ${JOURNEY_COLOURS[journey]}`}>Success!</h1>
        <p>{JOURNEY_SUCCESS_MESSAGES[journey]}</p>
      </div>
      <div className={`success-tick-icon ${JOURNEY_COLOURS[journey]}`}>
        <FontAwesomeIcon icon={faCheckCircle} color={JOURNEY_COLOURS[journey]} inverse/>
      </div>
    </div>
  );
};

export default SuccessCard;
