import React, { useEffect } from "react";
import Button from "../components/global/Button";
import Input from "../components/global/Input";
import Loader from "../components/global/Loader";
import "../styles/free_day_pass/signup.scss";
import "../styles/global/login.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { login, postMessageToParent } from "./Client";
import {
  ALT_MEMBER_TOKEN_NAME,
  FRONTEND_BASE_URL,
  MEMBER_TOKEN_MAX_AGE,
  RECAPTCHA_SITE_KEY,
  RTV_BASE_URL
} from "./constants";
import { ReactComponent as Chevron } from "../../src/svg/chevron.svg";
import { useHistory } from "react-router-dom";
import Alert from "../components/global/Alert";
import { parseSearch } from "./helpers";

export type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [rukMemberToken, setRukMemberToken] = React.useState("");
  const [sessionHash, setSessionHash] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [applePaymentLoading, setApplePaymentLoading] = React.useState(false);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const platform = parseSearch(history.location.search, "platform");

  useEffect(() => {
    if (rukMemberToken) {
      // set cookie now

      postMessageToParent("close");
      postMessageToParent(
        `cookie:${ALT_MEMBER_TOKEN_NAME}=${rukMemberToken};path=/members;max-age=${MEMBER_TOKEN_MAX_AGE}`
      );
      postMessageToParent(
        `cookie:member=${encodeURI(sessionHash)};path=/;max-age=${MEMBER_TOKEN_MAX_AGE}`
      );

      //                         message, parent address
      postMessageToParent("login_success:");
    }
  }, [sessionHash, rukMemberToken]);

  const showBack = () => {
    const prevPath = history.location.state as { from: string };
    return prevPath?.from === "signup";
  };

  const triggerAppleDayPass = () => {
    setApplePaymentLoading(true);
    postMessageToParent("apple-day-pass-purchase");

    // this is much easier than having two way communication with the parent
    // to stop loading spinner if the user cancels the apple payment
    setTimeout(() => {
      setApplePaymentLoading(false);
    }, 3000);
  };

  const VerifyAndProceed = async () => {
    // now set custom errors
    if (!email) return setError("Email is required");
    if (!password) return setError("Password is required");
    setLoading(true);

    // this can run forever if running outside of localhost in dev mode, i.e 192....
    const token = (await recaptchaRef?.current?.executeAsync()) || "bad-token";

    login(email, password, token)
      .then((res: any) => {
        setSessionHash(JSON.stringify(res.member));
        setRukMemberToken(res.cookie);
        console.log("REACT APP ENV: ", process.env.REACT_APP_ENV);
        // debugger; // this is going in as undefined
        sessionStorage.setItem("memberToken", res.jwt_token);
        sessionStorage.setItem("memberId", res.member.id);
        // sessionStorage.setItem("firstName", res.member.given_name);
        // sessionStorage.setItem("lastName", res.member.family_name);

        localStorage.setItem("memberToken", res.jwt_token);
        localStorage.setItem("memberId", res.member.id);
        // localStorage.setItem("firstName", res.member.given_name);
        // localStorage.setItem("lastName", res.member.family_name);

        postMessageToParent(res); // pass logged in member data to parent
        postMessageToParent("close");

        setError("");
      })
      .catch((err: any) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="space-top">
        {showBack() ? (
          <span className="back white-link" onClick={() => window.history.back()}>
            <Chevron className="left-chevron" /> Back
          </span>
        ) : null}
        <h1 className="login-header accent center">Member Login</h1>
        <Alert>
          If you are having issues logging in, please clear your site data and try again. See
          instructions for{" "}
          <span
            className="alert-link"
            onClick={() => {
              postMessageToParent({
                navigate: true,
                externalUrl:
                  "https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
              });
            }}>
            Chrome
          </span>
          ,{" "}
          <span
            className="alert-link"
            onClick={() => {
              postMessageToParent({
                navigate: true,
                externalUrl: "https://www.laptopmag.com/uk/articles/safari-delete-cookies"
              });
            }}>
            Safari
          </span>{" "}
          and{" "}
          <span
            className="alert-link"
            onClick={() => {
              postMessageToParent({
                navigate: true,
                externalUrl:
                  "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox#w_clear-all-cookies-site-data-and-cache"
              });
            }}>
            Firefox
          </span>
        </Alert>
        <Input
          id="email"
          placeHolder="Email or username"
          required
          type="email"
          value={email}
          updateFunc={(v: any) => setEmail(v.target.value)}
          autoComplete={"username"}
        />
        <Input
          id="password"
          placeHolder="Password"
          required
          value={password}
          type="password"
          autoComplete={"current-password"}
          updateFunc={(v: any) => setPassword(v.target.value)}
        />
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITE_KEY} />
        <div>
          <p className="micro-text">
            This site is protected by reCAPTCHA and the google&nbsp;
            <span
              className="accent-link"
              onClick={() => {
                postMessageToParent({
                  navigate: true,
                  externalUrl: "https://policies.google.com/privacy"
                });
              }}>
              Privacy Policy
            </span>
            &nbsp;and&nbsp;
            <span
              className="accent-link"
              onClick={() =>
                postMessageToParent({
                  navigate: true,
                  externalUrl: "https://policies.google.com/terms"
                })
              }>
              Terms of Service
            </span>
            &nbsp; apply.
          </p>
          {error ? <p className="error-text">{error}</p> : null}
        </div>
        <div className="center">
          <Button
            type="submit"
            text="Login"
            size="medium"
            color="accent"
            rightComponent={loading ? <Loader /> : null}
            onClick={(e: any) => VerifyAndProceed()}
          />
        </div>
      </div>

      <div className="space-top"></div>
      <span className="">
        Forgotten password?
        <span
          className="accent-link"
          onClick={() => {
            postMessageToParent({ navigate: true, internalUrl: "/my-account/forgot-password" });
          }}>
          {" "}
          Reset here
        </span>
      </span>

      <div className="space-top"></div>

      {Object.keys(platform)?.length && platform.platform == "ios" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              margin: "20px 0"
            }}>
            <hr
              style={{
                flexGrow: 1,
                border: "none",
                borderTop: "1px solid white",
                margin: "0 10px"
              }}
            />
            <span style={{ whiteSpace: "nowrap" }}>OR If you don't have an account</span>
            <hr
              style={{
                flexGrow: 1,
                border: "none",
                borderTop: "1px solid white",
                margin: "0 10px"
              }}
            />
          </div>
          <div className="center">
            <Button
              type="submit"
              text="Buy Day Pass £9.99"
              size="medium"
              color="black"
              rightComponent={applePaymentLoading ? <Loader /> : null}
              onClick={triggerAppleDayPass}
            />
          </div>
        </>
      ) : (
        <span className="">
          Already have a Day Pass but haven't created your account yet?
          <span className="accent-link" onClick={() => history.push(`/join?redeemingPass=true`)}>
            {" "}
            Create here
          </span>
        </span>
      )}
    </>
  );
};

export default Login;
