import React from "react";
import "../../styles/global/card.scss";
import { RTV_BASE_URL } from "../../shared/constants";

export type CardProps = {
  club_days_link?: string;
  header?: string;
  body?: any;
  rightSideIcon?: any;
  price?: string;
  duration?: string;
  border?: boolean;
  summary?: boolean;
  summaryContent?: Array<{
    name: string;
    price: string;
  }>;
  contentHeader?: string;
  smallPrint?: string;
  currencySymbol?: string;
};

const Card: React.FC<CardProps> = ({
  header,
  body,
  club_days_link,
  rightSideIcon,
  price,
  duration,
  border = true,
  summary,
  summaryContent,
  contentHeader,
  smallPrint,
  currencySymbol
}) => {
  return (
    <div className={`card-container ${border ? "border" : null}`}>
      {sessionStorage.skyQChecked == "true" && summary && summaryContent ? (
        <div className="summary-container">
          <div className="summary-content">
            {summaryContent.map((item) => {
              return (
                <div>
                  <div className="summary-item">
                    <p>Racing TV Subscription:</p>
                    <p>
                      {currencySymbol}
                      {item.name.toLocaleLowerCase().includes("annual")
                        ? (parseFloat(item.price) - 120).toFixed(2)
                        : (parseFloat(item.price) - 10).toFixed(2)}
                    </p>
                  </div>
                  <div className="summary-item">
                    <p>Sky Q mini access:</p>
                    <p>
                      {currencySymbol}
                      {item.name.toLowerCase().includes("annual") ? 120 : 10}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="horizontal-rule"></hr>
        </div>
      ) : null}

      <div className="columns w-76 w-100">
        {contentHeader ? (
          <div className="contentHeader">
            <p>
              <p className="row-setter">{contentHeader}</p>
              <p className="card-duration">{duration}</p>
            </p>
          </div>
        ) : null}
        <div className="card-content-container">
          <div
            className={`card-content ${header === "Racing TV Apps" ? "card-content-align" : ""}`}>
            <p className={`card-header`}>{header === "Racing TV Apps" ? null : header}</p>
            <div className="card-body m-10">
              <span className={`${header === "Racing TV Apps" ? "racing-tv-apps" : ""}`}>
                {body}
                {club_days_link && (
                  <span
                    className="accent-link"
                    onClick={() => window.open(`${RTV_BASE_URL}members/club_days`, "_blank")}>
                    {club_days_link}
                  </span>
                )}
              </span>
            </div>
          </div>
          {price && (
            <div className="card-central-content">
              <p className="card-price">
                {currencySymbol}
                {price}
              </p>
            </div>
          )}
          {rightSideIcon ? <div className="card-right-icon">{rightSideIcon}</div> : null}
        </div>
      </div>
      {smallPrint ? (
        <div className="small-print-container">
          <p>{smallPrint}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Card;
