import React from "react";
import "../../styles/global/alert.scss";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type AlertProps = {};

const Alert: React.FC<AlertProps> = ({ children }) => {
  return (
    <>
      <div className="alert-container">
        <div className="alert-header">
          <FontAwesomeIcon icon={faBell} />
          Notice
        </div>
        <div className="alert-text">{children}</div>
      </div>
    </>
  );
};

export default Alert;
