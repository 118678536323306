import React, { FunctionComponentElement, SVGProps } from "react";
import { useState } from "react";
import { ReactComponent as IrelandFlag } from "../../svg/flags/ireland.svg";
import { ReactComponent as UKFlag } from "../../svg/flags/uk.svg";
import { ReactComponent as WorldFlag } from "../../svg/flags/world.svg";

export type Flag = {
  code: string;
  svg: React.FunctionComponent;
}

export type Country = {
  id: number,
  name: string,
  iso2: string,
  flag: React.FunctionComponent
}

const flags: Flag[] = [
  {
    code: "ROW",
    svg: WorldFlag
  },
  {
    code: "UK",
    svg: UKFlag
  },
  {
    code: "IE",
    svg: IrelandFlag
  },
];

type FlagProps = { code: string }

const Flag: React.FC<FlagProps> = ({code}) => {
  const flag = flags.find(f => f.code === code) || flags[0];

  return (
    <div style={{width: '16px', height: '16px', padding: '0.5rem', cursor: 'pointer', display: 'flex' }}>
      {React.createElement(flag.svg) }
    </div>
  )
};

export default Flag
