// declare dataLayer as global
declare global {
  interface Window {
    dataLayer: TrackUserOption[];
  }
}

type TrackUserOption = {
  event:
    | "data_entry_reached"
    | "data_entry_completed"
    | "select_package_completed"
    | "your_details_completed"
    | "payment_stage_completed"
    | "subscription_completed";
  join_option_selected?: {
    plan_name?: string;
    consent_marketing?: "1" | "0";
    consent_third_party?: "1" | "0";
    consent_racecourse_affiliates?: "1" | "0";
  };
};

export function trackUserOption({ event, join_option_selected }: TrackUserOption) {
  window.dataLayer.push({
    event,
    join_option_selected
  });
}
