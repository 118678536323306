import React, { useEffect } from "react";
import "../styles/free_day_pass/success.scss";
import Button from "../components/global/Button";
import SuccessCard from "../components/global/SuccessCard";
import { JOURNEY_COLOURS, JourneyType } from "../shared/Theme";
import { getLiveProgrammes, postMessageToParent } from "../shared/Client";
import Card from "../components/global/Card";
import Videos from "../components/global/Videos"; // must come after card
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import LoadSuccessPageAnalytics from "../components/global/LoadSuccessPageAnalytics";
import { ReactComponent as AndoridTv } from "../svg/andriod_tv.svg";
import { ReactComponent as Andorid } from "../svg/andorid.svg";
import { ReactComponent as FireTv } from "../svg/fire_tv.svg";
import { ReactComponent as AppleTv } from "../svg/apple_tv.svg";
import { FRONTEND_BASE_URL } from "../shared/constants";
import { trackUserOption } from "../utils/tracking";

export type SuccessProps = {
  journey: JourneyType;
};

export type LiveProgramme = {
  title: string;
  subtitle: string;
  image_url: string;
  video_subtype: string;
  video_id: number | null;
};

// const data = {
//   "meta": { "status": 200, "code": "OK" },
//   "live_programmes": [
//     {
//       "title": "The Friday Club",
//       "subtitle": "This evening's racing action comes to you live from Dundalk.",
//       "image_url": "https://staging.racingtv.com/assets/videos/live/jumps-2-21d5226a9ff81492c4a618d3846899137648466ef4688470c9ffe6a102a831ab.jpg",
//       "video_subtype": "live",
//       "video_id": null
//     }]
// }

const Success: React.FC<SuccessProps> = ({ journey }) => {
  // const [liveProgrammes, setLiveProgrammes] = React.useState<LiveProgramme[]>([]);

  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
  //   // localStorage.clear()
  //   // Update the document title using the browser API
  //   // https://staging.racingtv.com/api/programmes/live
  //   getLiveProgrammes()
  //     .then((res: any) => setLiveProgrammes(res.live_programmes))
  //     .catch((err: any) => console.log(err));
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    trackUserOption({ event: "subscription_completed" });
  }, []);

  return (
    <>
      {LoadSuccessPageAnalytics()}

      <SuccessCard journey={journey} />
      {/* <Card header="Watch Now" body={<Videos videos={liveProgrammes} />} border={false} /> */}
      <div className="center" style={{ marginTop: 10 }}>
        <Button
          type="submit"
          text="Watch Now"
          size="medium"
          color={JOURNEY_COLOURS[journey]}
          rightComponent={null}
          onClick={() =>
            postMessageToParent(
              { signup_complete: true, jwt_token: sessionStorage.getItem("memberToken") },
              FRONTEND_BASE_URL
            )
          }
        />
      </div>
      {/* <Card
        header="Watch now"
        body={
        }
        border={false}
      /> */}

      <Card
        header="...Or on the go"
        body={
          <div className="flex" style={{ marginTop: 15 }}>
            <a
              style={{ marginRight: 10 }}
              target="_blank"
              href="https://itunes.apple.com/gb/app/racing-uk-live-horse-racing/id352776768?mt=8">
              <FontAwesomeIcon icon={faApple} />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.racinguk.app">
              <Andorid className="icon" />
            </a>
          </div>
        }
        border={false}
      />
      <br />
      <Card
        header="...Or on the big screen"
        body={
          <div style={{ marginTop: 10, marginLeft: 22 }}>
            <a
              target="_blank"
              href="https://www.racingtv.com/largescreenapp"
              style={{ marginRight: 10 }}>
              <AppleTv className="icon" />
            </a>
            <a
              style={{ marginRight: 10 }}
              target="_blank"
              href="https://www.racingtv.com/largescreenapp">
              <FireTv className="icon" />
            </a>
            <a target="_blank" href="https://www.android.com/tv/">
              <AndoridTv className="tv-icon" />
            </a>
          </div>
        }
        border={false}
      />
    </>
  );
};

export default Success;
