import React from "react";
import "../../styles/global/progressBar.scss";

export type ProgressBarProps = {
  activeDots: number;
  numberOfDots: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ activeDots, numberOfDots }) => {
  const calculateWidthOfActiveLine = (numberOfDots: number) => {
    // 98% of parent container to compensate padding
    const widthOfTotalLine = 98;
    let distanceBetweenEachDot = widthOfTotalLine / (numberOfDots - 1);
    return distanceBetweenEachDot;
  };

  return (
    <div className="progress-bar-container">
      <p className="step-count-text m-0">
        <strong>{`Step ${activeDots.toString()}`}</strong>
        {` of ${numberOfDots.toString()}`}
      </p>
      <div className="progress-bar">
        <hr
          className="progress-bar-line-active"
          style={{
            width: `${calculateWidthOfActiveLine(numberOfDots) * (activeDots - 1)}%`
          }}></hr>
        <hr className="progress-bar-line"></hr>
        {[...Array(activeDots)].map((el, index) => {
          return <div key={index} className="progress-bar-dot active"></div>;
        })}
        {[...Array(numberOfDots - activeDots)].map((el, index) => {
          return <div key={index} className="progress-bar-dot"></div>;
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
