export const JOURNEY_COLOURS = {
  join: "accent",
  free_day_pass: "accent-secondary"
};

export const JOURNEY_SUCCESS_MESSAGES = {
  join: "You can now watch live racing as a Racing TV member",
  free_day_pass: "You can now watch live racing!"
}

export type JourneyType = "free_day_pass" | "join";

export const JOURNEY_CTA_TEXT = {
  join: "Get Started",
  free_day_pass: "Get Code"
}
