import React, { useEffect } from "react";
import Input from "../components/global/Input";
import Button from "../components/global/Button";
import { useHistory, useLocation } from "react-router-dom";
import { JourneyType, JOURNEY_COLOURS } from "../shared/Theme";
import {
  ALT_MEMBER_TOKEN_NAME,
  FREE_DAY_PASS_MAX_AGE,
  MEMBER_TOKEN_MAX_AGE,
  MEMBER_TOKEN_NAME,
  RTV_BASE_URL
} from "../shared/constants";
import { validateCode } from "../shared/Client";
import Loader from "../components/global/Loader";
import DropText from "../components/global/DropText";
import SearchAddress from "../shared/SearchAddress";
import { CountryCode } from "../shared/Models";
import countries from "../data/countries.json";

export const CODE_LENGTH = 6;
export type VerifyProps = {
  journey: JourneyType;
};
type SelectedAddress = {
  addressline1: string;
  addressline2: string;
  addressline3?: string;
  summaryline: string;
  subbuildingname?: string;
  number: string;
  premise?: string;
  street: string;
  posttown: string;
  county: string;
  postcode: string;
};

const Verify: React.FC<VerifyProps> = ({ journey }) => {
  const restOfWorld = sessionStorage.getItem("restOfWorld") == "true" ? true : false;
  const lastCountryCode = sessionStorage.getItem("countryCode");

  const history = useHistory();
  const location: any = useLocation();
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [skyNumber, setSkyNumber] = React.useState<string>("");
  const [skyNumberWrongLength, setSkyNumberWrongLength] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [pattern, setPattern] = React.useState("");
  const [manualAddress, setManualAddress] = React.useState(false);
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [address3, setAddress3] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("+44");
  const [countryCode, setCountryCode] = React.useState<CountryCode>("UK");
  const [validForm, setValidForm] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState<SelectedAddress | undefined>(
    undefined
  );
  const [searchingRestOfWorld, setSearchingRestOfWorld] = React.useState(
    restOfWorld ? restOfWorld : false
  );
  const [country, setCountry] = React.useState(lastCountryCode ? lastCountryCode : "UK");

  const mapCountry = (countryCode: CountryCode): string => {
    if (!searchingRestOfWorld) {
      const selectedNonRowCountry = countries.find((c) => c.iso2 === countryCode)?.iso3;
      if (!selectedNonRowCountry)
        throw new Error("Selected country code not found in countries.json");

      return selectedNonRowCountry;
    }
    return "GBR"; // Return ROW in all other cases
  };

  const VerifyAndProceed = () => {
    setLoading(true);

    const token = new URLSearchParams(location.search).get("token");
    const member = new URLSearchParams(location.search).get("member");
    const givenName = sessionStorage.getItem("firstName");
    const familyName = sessionStorage.getItem("lastName");

    if ((!address1 && !city && !postcode) || !token || !givenName || !familyName) {
      setError("Sorry, something went wrong. Please try again.");

      return setLoading(false);
    }

    if (address1.length > 50) return setError("Address Line1 exceeds the maximum limit of 50.");
    else if (address2?.length > 50)
      return setError("Address Line2 exceeds the maximum limit of 50.");
    else if (address3?.length > 50)
      return setError("Address Line3 exceeds the maximum limit of 50.");
    const customer = {
      customer_attributes: {
        given_name: givenName,
        family_name: familyName,
        phone: phoneNumber,
        address_attributes: {
          address1: address1,
          address2: address2,
          address3: address3,
          city: city,
          postcode: postcode,
          country_code: mapCountry(countryCode) // we wanna post iso3, not iso2 used by the address finder api
        }
      },
      passcode: code,
      vcn_number: skyNumber.replace(" ", "").replace(/-/g, "")
    };

    validateCode(member, token, customer)
      .then((res) => {
        if (journey === "free_day_pass") {
          sessionStorage.setItem("restOfWorld", searchingRestOfWorld ? "true" : "false");
          sessionStorage.setItem("countryCode", countryCode);
          // set cookie now
          window.parent.postMessage(
            `cookie:free_day_pass_set=${new Date().toLocaleDateString(
              "en-CA"
            )};path=/;max-age=${FREE_DAY_PASS_MAX_AGE};`,
            RTV_BASE_URL
          );
          // window.parent.postMessage(`cookie:member=${encodeURI(member)};path=/;max-age=${MEMBER_TOKEN_MAX_AGE}`, RTV_BASE_URL);
          // window.parent.postMessage(`cookie:${MEMBER_TOKEN_NAME}=${encodeURI(JSON.parse(member).session_token)};path=/;max-age=${MEMBER_TOKEN_MAX_AGE}`, RTV_BASE_URL);
          // window.parent.postMessage(`cookie:${ALT_MEMBER_TOKEN_NAME}=${encodeURI(JSON.parse(member).session_token)};path=/;max-age=${MEMBER_TOKEN_MAX_AGE}`, RTV_BASE_URL);

          history.push("/fdp/success");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const validPhone = !!phoneNumber.match(/\+?[0-9]{5,15}/);
    const CodeLength = code.length >= CODE_LENGTH ? true : false;
    address1 && city && postcode && validPhone && !skyNumberWrongLength && CodeLength
      ? setValidForm(true)
      : setValidForm(false);
  }, [code, manualAddress, address1, city, postcode, phoneNumber, skyNumberWrongLength]);

  // keeps button disabled until while entered sky code is either omitted or correct length
  useEffect(() => {
    const strippedSkyNumber = skyNumber.replace(/\s/g, "");

    if (skyNumber.length === 0) {
      return setSkyNumberWrongLength(false);
    }

    if (strippedSkyNumber.length === 11) {
      return setSkyNumberWrongLength(false);
    }

    setSkyNumberWrongLength(true);
  }, [skyNumber]);

  return (
    <>
      <div>
        <h1 className={JOURNEY_COLOURS[journey]}>
          Great! Just <span className="bold-heading-accent">activate your code</span> to watch live
          racing
        </h1>
        <p className="left-text">Please check your inbox for your unique code</p>
        <Input
          type="text"
          placeHolder={`Enter your ${CODE_LENGTH} character code`}
          minLength={CODE_LENGTH}
          maxLength={CODE_LENGTH}
          required
          updateFunc={(e: any) => setCode(e.target.value)}
        />
        {error ? <p className="error-text">{error}</p> : null}
        <DropText
          text="Activate on Sky"
          label="Your 9 digit Sky Viewing Card Number"
          minLength={9}
          maxLength={11}
          value={skyNumber}
          setValue={setSkyNumber}
        />
        {skyNumberWrongLength ? (
          <p className="error-text">
            Your Sky Viewing Card Number must be 9 digits excluding spaces
          </p>
        ) : null}
        <SearchAddress
          pattern={pattern}
          setPattern={setPattern}
          manualAddress={manualAddress}
          setManualAddress={setManualAddress}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          address3={address3}
          setAddress3={setAddress3}
          city={city}
          setCity={setCity}
          postcode={postcode}
          setPostcode={setPostcode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          setSearchingRestOfWorld={setSearchingRestOfWorld}
          country={country}
          setCountry={setCountry}
        />
        <div className="center space-top">
          <Button
            type="sumbit"
            text="Watch Live Racing"
            size="medium"
            disabled={!validForm}
            color={JOURNEY_COLOURS[journey]}
            rightComponent={loading ? <Loader color={JOURNEY_COLOURS[journey]} /> : null}
            onClick={() => VerifyAndProceed()}
          />
        </div>
      </div>
      <p className="italic left-text">
        Your code will expire within 72 hours. If not redeemed before then the code will no longer
        be valid
      </p>
      <hr className="wide"></hr>
      <h1 className="center racing_tv_text">Want to watch racing all year round?</h1>
      <div className="center">
        <Button
          type="submit"
          text="Join Racing TV"
          size="medium"
          color="accent"
          rightComponent={null}
          onClick={() => (window.location.href = "/")}
        />
      </div>
    </>
  );
};

export default Verify;
