import React from "react";
import Input from "../components/global/Input";

import { getAddressDetail, lookupAddress, lookupRestOfWorldAddress } from "../shared/Client";
import Loader from "../components/global/Loader";
import "../styles/join/details.scss";

import countries from "../data/countries.json";
import Flag from "../components/global/Flag";
import { CountryCode } from "./Models";

export type SearchAddress = {
  pattern: string;
  setPattern: any;
  manualAddress: string;
  setManualAddress: any;
  address1: string;
  setAddress1: any;
  address2: string;
  setAddress2: any;
  address3: string;
  setAddress3: any;
  city: string;
  setCity: any;
  postcode: string;
  setPostcode: any;
  phoneNumber: string;
  setPhoneNumber: any;
  countryCode: string;
  setCountryCode: any;
  setSelectedAddress?: any;
  selectedAddress: string;
  setSearchingRestOfWorld: any;
  country: string;
  setCountry: any;
};

type SuggestedAddress = {
  international: boolean;
  id: string;
  type: string;
  summaryline: string;
  locationsummary: string;
  count: number;
};

type SuggestedInternationalAddress = {
  international: boolean;
  type: null;
  id: string;
  locationsummary: null;
  addressline1: string;
  addressline2: string;
  country: string;
  county: string;
  number: string;
  postcode: string;
  posttown: string;
  street: string;
  summaryline: string;
};

export type Country = {
  id: number;
  name: string;
  code: string;
};

export type Address = {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  postcode: string;
  country_code: string;
};

export type SkyQ = "0" | "1";

const SearchAddress: React.FC<any> = ({
  pattern,
  setPattern,
  manualAddress,
  setManualAddress,
  address1,
  setAddress1,
  address2,
  setAddress2,
  address3,
  setAddress3,
  city,
  setCity,
  postcode,
  setPostcode,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  setSelectedAddress,
  selectedAddress,
  setSearchingRestOfWorld,
  country,
  setCountry
}) => {
  const [addressLoading, setAddressLoading] = React.useState(false);
  const [suggestedAddresses, setSuggestedAddresses] = React.useState<SuggestedAddress[]>([]);
  const nonROWcountryIsoCodes = ["GB", "IE"];
  const MIN_ADDRESS_LENGTH = 3;
  const getRemainingCharacters = () => {
    const rem = MIN_ADDRESS_LENGTH - pattern.length;
    return rem > 0 ? rem : 0;
  };
  const remainingCharacters = getRemainingCharacters();

  const handleLookup = (pattern: string, pathFilter: string) => {
    setPattern(pattern);
    setSelectedAddress(undefined);
    if (!(pattern.length >= MIN_ADDRESS_LENGTH)) return;

    setAddressLoading(true);
    lookupAddress(pattern, countryCode, pathFilter, true)
      .then((res: any) => {
        setAddressLoading(false);
        // const addresses = res.data.results;
        setSuggestedAddresses(res);
      })
      .catch((err: any) => {
        setAddressLoading(false);
      })
      .finally(() => {
        setAddressLoading(false);
      });
  };

  const setCountryCodeAndClearResults = (countryCode: CountryCode) => {
    setCountryCode(countryCode);
    const searchingRestOfWorld = !nonROWcountryIsoCodes.includes(countryCode);
    setSearchingRestOfWorld(searchingRestOfWorld);
    setSuggestedAddresses([]);
    setSelectedAddress(undefined);
    setPattern("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPostcode("");
    setCity("");
  };

  // TODO: The finally setLoading(false) doesnt exceute if we push to history
  // so we'll have to setLoading(false) at the end of .then().
  // We wont have to do this for catch because it will always exceute.
  const selectAddress = (address: SuggestedAddress | SuggestedInternationalAddress) => {
    setAddressLoading(true);
    // if the address has no id- then the user needs to refine the addresses once more.
    // we use the pathfilter to refine the address

    if (!address.id.match(/^[0-9]+$/)) {
      return handleLookup(pattern, address.id);
    } else {
      getAddressDetail(address.id, countryCode).then((res) => {
        setSelectedAddress(res[0]);
        handleAddressChange(res[0]);
        setSuggestedAddresses([]);
        setAddressLoading(false);
      });
    }
  };

  const handleAddressChange = (address: any) => {
    setAddress1(address ? address.addressline1 : "");
    setAddress2(address ? address.addressline2 : "");
    setAddress3(address && address.addressline3 ? address.addressline3 : "");
    setPostcode(address ? address.postcode : "");
    setCity(address ? address.posttown : "");
  };

  return (
    <>
      <div className="input-outer-container">
        <div className="input-label-container">Country of Residence</div>
        <div className="input-container">
          <select
            id="country-select"
            className="input-text-area"
            value={countryCode}
            onChange={(e) => {
              setCountryCodeAndClearResults(e.target.value as CountryCode);
              setPhoneNumber(e.target.options[e.target.selectedIndex].dataset.code as CountryCode);
              setCountry(e.target.value);
            }}>
            {countries.map((c) => (
              <option key={c.iso2} value={c.iso2} data-code={c.countryCode}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <span className="row-spacer"></span>
      {country != "ROTW" && !manualAddress && (
        <Input
          key={"xyz"}
          leftComponent={<Flag code={countryCode} />}
          placeHolder="e.g. 221B Baker Street"
          label="Search Address"
          required
          type="postcode"
          value={selectedAddress ? selectedAddress.summaryline : pattern}
          updateFunc={(v: any) => handleLookup(v.target.value, "")}
        />
      )}
      {country != "ROTW" && remainingCharacters > 0 && !manualAddress ? (
        <p className="subtle italic left-text m-0">
          Enter {remainingCharacters} more character{remainingCharacters > 1 ? "s" : ""} of your
          address
        </p>
      ) : null}
      {!(selectedAddress != null && selectedAddress != undefined) && country != "ROTW" && (
        <button
          className="btn-container accent padding-3"
          onClick={() => {
            setManualAddress(!manualAddress);
            setSuggestedAddresses([]);
          }}>
          <span className="btn-text text-size">
            {manualAddress ? "Search Address" : "Enter Address Manually"}
          </span>
        </button>
      )}

      {(selectedAddress != null && selectedAddress != undefined) ||
      country === "ROTW" ||
      manualAddress ? (
        <>
          <Input
            key={"address1"}
            leftComponent={<Flag code={countryCode} />}
            placeHolder="Address 1"
            label="First line of address"
            required
            type="address1"
            value={address1 ? address1 : ""}
            updateFunc={(v: any) => setAddress1(v.target.value)}
          />
          <Input
            key={"address2"}
            leftComponent={<Flag code={countryCode} />}
            placeHolder="Address 2"
            label="Second line of address"
            type="address2"
            value={address2 ? address2 : ""}
            updateFunc={(v: any) => setAddress2(v.target.value)}
          />
          <Input
            key={"address3"}
            leftComponent={<Flag code={countryCode} />}
            placeHolder="Address 3"
            label="Third line of address"
            type="address3"
            value={address3 ? address3 : ""}
            updateFunc={(v: any) => setAddress3(v.target.value)}
          />
          <Input
            key={"city"}
            leftComponent={<Flag code={countryCode} />}
            placeHolder="City"
            label="City"
            required
            type="city"
            value={city ? city : ""}
            updateFunc={(v: any) => setCity(v.target.value)}
          />

          <Input
            key={"postcode"}
            leftComponent={<Flag code={countryCode} />}
            placeHolder="Postcode"
            label="Postcode"
            required
            type="postcode"
            value={postcode ? postcode : ""}
            updateFunc={(v: any) => setPostcode(v.target.value)}
          />
        </>
      ) : null}

      {suggestedAddresses.length ? (
        <div className="columns">
          <div className="suggested-addresses">
            <div
              key="-1" // as its always before first (0)
              className="suggested-address">
              <div className="suggested-address-text">Keep typing for more results...</div>
            </div>
            {suggestedAddresses.map(
              (address: SuggestedAddress | SuggestedInternationalAddress, index: number) => (
                <div
                  key={index}
                  className="suggested-address"
                  onClick={() => selectAddress(address)}>
                  <div className="suggested-address-text">
                    {address.summaryline}, {address.locationsummary} <br />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : null}
      {addressLoading ? <Loader color={"accent"} /> : <span className="space-top"></span>}
      <Input
        placeHolder="Phone Number"
        label="Phone Number"
        required
        type="tel"
        value={phoneNumber}
        updateFunc={(v: any) => setPhoneNumber(v.target.value)}
      />
    </>
  );
};

export default SearchAddress;
