import React from "react";
import "../../App.scss";
import "../../styles/global/lozenge.scss";

export type LozengeProps = {
  text?: string;
  textColor?: string;
  boxColor?: string;
};

const Lozenge: React.FC<LozengeProps> = ({ text = "", textColor, boxColor }) => {
  return (
    <div className="lozenge-container" style={{ backgroundColor: boxColor }}>
      <text className="lozenge-text" style={{ color: textColor }}>
        {text}
      </text>
    </div>
  );
};

export default Lozenge;
